/**
 * Configuration file used in frontend modules.
 * 
 */
 const env = 'production';
 
 const config = {
   env: env,
   service:'https://fc85v3kjt5.execute-api.eu-central-1.amazonaws.com/dev/relas-query?'
 };
 
 export { config };
 