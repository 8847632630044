/**
 * Application entry point.
 * 
 */
import _ from 'lodash';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

// Fonts
import './assets/fonts/VWAGTheSans-Bold.eot';
import './assets/fonts/VWAGTheSans-Bold.svg';
import './assets/fonts/VWAGTheSans-Bold.woff';
import './assets/fonts/VWAGTheSans-Bold.woff2';

import './assets/fonts/VWAGTheSans-Italic.eot';
import './assets/fonts/VWAGTheSans-Italic.svg';
import './assets/fonts/VWAGTheSans-Italic.woff';
import './assets/fonts/VWAGTheSans-Italic.woff2';

import './assets/fonts/VWAGTheSans-Light.eot';
import './assets/fonts/VWAGTheSans-Light.svg';
import './assets/fonts/VWAGTheSans-Light.woff';
import './assets/fonts/VWAGTheSans-Light.woff2';

import './assets/fonts/VWAGTheSans-Regular.eot';
import './assets/fonts/VWAGTheSans-Regular.svg';
import './assets/fonts/VWAGTheSans-Regular.woff';
import './assets/fonts/VWAGTheSans-Regular.woff2';

import './assets/fonts/VWAGTheAntiqua-Bold.eot';
import './assets/fonts/VWAGTheAntiqua-Bold.svg';
import './assets/fonts/VWAGTheAntiqua-Bold.woff';
import './assets/fonts/VWAGTheAntiqua-Bold.woff2';

import './assets/fonts/VWAGTheAntiqua-Italic.eot';
import './assets/fonts/VWAGTheAntiqua-Italic.svg';
import './assets/fonts/VWAGTheAntiqua-Italic.woff';
import './assets/fonts/VWAGTheAntiqua-Italic.woff2';

import './assets/fonts/VWAGTheAntiqua-Regular.eot';
import './assets/fonts/VWAGTheAntiqua-Regular.svg';
import './assets/fonts/VWAGTheAntiqua-Regular.woff';
import './assets/fonts/VWAGTheAntiqua-Regular.woff2';

import './assets/fonts/groupui-vwag-icons-complete.eot';
import './assets/fonts/groupui-vwag-icons-complete.ttf';
import './assets/fonts/groupui-vwag-icons-complete.woff';
import './assets/fonts/groupui-vwag-icons-complete.woff2';

// Corporate CSS
import './assets/corporate/vwag/vwag.min.css';
import './assets/css/styles.css';

import RelasQueryHandler from './relas-query-handler';

new RelasQueryHandler();
