import * as log from 'loglevel';
import { config } from './config';

const isProdMode = config.env == 'production' ? true : false;
const level = isProdMode ? log.levels.WARN : log.levels.DEBUG;
log.setLevel(level);

class RelasQueryHandler {

  constructor() {
    log.debug('constructor');
    this._cacheDomElements();
    this._addListeners();
    this._service = config.service;
  }

  _cacheDomElements() {
    log.debug('_cacheDomElements');
    this._form = document.querySelector('form');
    this._loader = document.querySelector('.is-pulsing');
    this._resultSection = document.querySelector('#result-section');
    this._languageInput = document.querySelector('input[name="language"]');
    this._modelkeyInput = document.querySelector('input[name="modelkey"]');
    this._testdateInput = document.querySelector('input[name="testdate"]');
    this._prfilterInput = document.querySelector('input[name="prfilter"]');
  }

  _addListeners() {
    log.debug('_addListeners');
    this._form.addEventListener('submit', this._loadData.bind(this));
  }

  _showLoader(isVisible) {
    log.debug('_showLoader');
    this._loader.hidden = !isVisible;
    this._loader.style.margin = isVisible ? '24px' : 0;
  }

  _loadData(e) {
    log.debug('_loadData');
    e.preventDefault();

    if (this._isValid()) {
      this._resultSection.innerHTML = '';
      this._showLoader(true);

      return window.fetch(this._buildUrl(), {
        method: 'get'
      }).then(res => {
  
        return res.json();
  
      }).then(json => {
        this._showLoader(false);
  
        if (json == null) {
          this._showNoResult();
        } else if (json.tyres == null) {
          this._showNoResult();
        } else {
          this._appendResult(json.tyres);
        }
  
      }).catch(function (error) {
  
        this._showLoader(false);
  
        log.error('Looks like there was a problem: \n', error);
  
      });
    } else {
      this._showInvalidFormError();
    }
  }

  _isValid() {
    const invalid = _.isEmpty(this._languageInput.value) || _.isEmpty(this._modelkeyInput.value) || _.isEmpty(this._testdateInput.value);
    return !invalid;
  }

  _buildUrl() {
    const language = 'language=' + this._languageInput.value + '&';
    const modelkey = 'modelkey=' + this._modelkeyInput.value + '&';
    const testdate = 'testdate=' + this._testdateInput.value + '&';
    const prfilter = 'prfilter=' + this._prfilterInput.value + '&';
    const format = 'format=json';

    const url = this._service.concat(language).concat(modelkey).concat(testdate).concat(prfilter).concat(format);

    return url;
  }

  _showNoResult() {
    log.debug('_showNoResult');

    const content = `
          <i class="icon i-idea"></i>
          <div class="notification__content">
              No results found.
          </div>
      `;

    let el = document.createElement('div');
    el.classList.add('notification');
    el.classList.add('is-attention');
    el.classList.add('is-outlined');
    el.classList.add('mar-bottom-small');
    el.innerHTML = content;

    this._resultSection.appendChild(el);
  }

  _showInvalidFormError() {
    log.debug('_showInvalidFormError');

    this._resultSection.innerHTML = '';

    const content = `
          <i class="icon i-idea"></i>
          <div class="notification__content">
            Please fill in required fields.
          </div>
      `;

    let el = document.createElement('div');
    el.classList.add('notification');
    el.classList.add('is-uberdanger');
    el.classList.add('is-outlined');
    el.classList.add('mar-bottom-small');
    el.innerHTML = content;

    this._resultSection.appendChild(el);
  }

  _appendResult(data) {
    log.debug('_appendResult');

    _.each(data, (item, key) => {

      const brand = item.tyreSpecification.attributes.tradeName;
      const product = item.tyreSpecification.attributes.profileName;
      const size = item.tyreSpecification.attributes.sizeDesignation.tyreSizeDesignation;
      const prNumber = item.tyreSpecification.attributes.selectionCriteria.prNumbers[0];
      const tyreLabelUrl = item.tyreLabelUrl;
      const productSheetUrl = item.productSheetUrl;

      const content = `
          <div class="card__content">
  
            <table class="table">
              <thead>
                <tr>
                  <th>Company name</th>
                  <th>Tyre product</th>
                  <th>Tyre size</th>
                  <th>PR-Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${brand}</td>
                  <td>${product}</td>
                  <td>${size}</td>
                  <td>${prNumber}</td>
                </tr>
              </tbody>
            </table>
            
            <hr>
            
            <h3 class="subtitle font-size-small">Link Tyre Label</h3>
            <a class="font-size-small" href="${tyreLabelUrl}" target="_blank">${tyreLabelUrl}</a>
            <br>
            <h3 class="subtitle font-size-small">Link Datasheet</h3>
            <a class="font-size-small" href="${productSheetUrl}" target="_blank">${productSheetUrl}</a>
  
          </div>
        `;

      let resultCard = document.createElement('div');
      resultCard.classList.add('card');
      resultCard.innerHTML = content;

      this._resultSection.appendChild(resultCard);
    });
  }

}

export default RelasQueryHandler;
